import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  Text,
} from '@chakra-ui/react';

interface ResponseCardProps {
  elements: GloomhavenSearchResult[];
}

export const ResponseCard = (props: ResponseCardProps) => {
  const { elements } = props;

  return (
    <Accordion allowMultiple>
      {elements.map((el) => (
        <AccordionItem key={el.id}>
          <AccordionButton display="flex" justifyContent="space-between">
            <CircularProgress value={el.score * 100} color="green.400">
              <CircularProgressLabel>
                {Math.round(el.score * 100)}%
              </CircularProgressLabel>
            </CircularProgress>
            <Heading size="sm" textAlign="left" flexGrow={1} ml={4} mr={4}>
              {el.payload.question}
            </Heading>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text>{el.payload.answer}</Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export interface GloomhavenSearchResult {
  id: string;
  score: number;
  payload: {
    answer: string;
    question: string;
    uuid: string;
  };
}
