import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

interface FormSubmitButtonProps {
  text: string;
}

export const FormSubmitButton = (props: FormSubmitButtonProps) => {
  const { text } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <Button variant="solid" isLoading={isSubmitting} type="submit" ml="auto">
      {text}
    </Button>
  );
};
