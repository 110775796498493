import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

interface FormTextareaProps<K> {
  name: K;
  label: string;
  placeholder?: string;
  helperText?: string;
}

export const FormTextarea = <
  K extends string,
  T extends { [key in K]: string }
>(
  props: FormTextareaProps<K>
) => {
  const { label, name, placeholder, helperText } = props;
  const { handleChange, values, errors, touched } = useFormikContext<T>();

  return (
    <FormControl isInvalid={!!errors[name] && !!touched[name]}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        id={name}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={values[name]}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errors[name] as string}</FormErrorMessage>
    </FormControl>
  );
};
