import { useMemo, useState } from 'react';

type Status = 'idle' | 'pending' | 'resolved' | 'rejected';

export const useStatus = () => {
  const [status, setStatus] = useState<Status>('idle');
  const helpers = useMemo(
    () => ({
      isFetching: status === 'pending',
      isRejected: status === 'rejected',
      isResolved: status === 'resolved',
      isIdle: status === 'idle',
      isLoading: status === 'idle' || status === 'pending',
    }),
    [status]
  );

  return { status, setStatus, ...helpers };
};
