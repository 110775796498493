import {
  Alert,
  AlertDescription,
  AlertIcon,
  Center,
  Code,
  Divider,
  Heading,
  ListItem,
  Spinner,
  UnorderedList,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormTextarea } from '../../components/forms/FormTextarea';
import { validationSchema } from './search.validation';
import { GloomhavenSearchResult, ResponseCard } from './ResponseCard';
import { useCallback, useState } from 'react';
import { useStatus } from '../../hooks/useStatus';
import { FormSubmitButton } from '../../components/forms/FormSubmitButton';

export const Gloomhaven = () => {
  const [answers, setAnswers] = useState<GloomhavenSearchResult[]>([]);
  const toast = useToast();
  const { isFetching, isRejected, setStatus, isResolved } = useStatus();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSubmit = useCallback(
    async (values: { query: string }, fb: FormikHelpers<{ query: string }>) => {
      try {
        setStatus('pending');
        const resp = await fetch(
          'https://skippy.korczas.dev/gloomhaven/search',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query: values.query, limit: 7 }),
          }
        );
        const data = await resp.json();
        if (!resp.ok) {
          setStatus('rejected');
          setErrorMessage(data.message.join('\n'));
        } else {
          setStatus('resolved');
          setAnswers(data);
        }
      } catch (e: any) {
        toast({
          title: 'Error',
          description: JSON.stringify(e, null, 2),
          status: 'error',
          isClosable: true,
          duration: 10000,
        });
        setStatus('rejected');
        setErrorMessage(e.message);
      }
    },
    [setStatus, toast]
  );

  return (
    <>
      <Heading mb={4}>
        Here are a few tips on how to search effectively:
      </Heading>
      <UnorderedList>
        <ListItem>
          To search for an item, type: "Item" followed by the item number, e.g.,{' '}
          <Code>Item 136</Code>
        </ListItem>
        <ListItem>
          To search for a scenario, type: "Scenario" followed by the scenario
          number, e.g., <Code>Scenario 93</Code>
        </ListItem>
        <ListItem>
          To check a character's skill, simply enter the skill name, e.g.,{' '}
          <Code>Backup ammunition</Code>
        </ListItem>
        <ListItem>You can ask questions in any language!</ListItem>
      </UnorderedList>

      <Divider mb={4} mt={4} />

      <Formik
        initialValues={{ query: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <VStack mb={8}>
            <FormTextarea name="query" label="Ask your question:" />
            <FormSubmitButton text="Submit" />
          </VStack>
          {isFetching && (
            <Center>
              <Spinner size="xl" />
            </Center>
          )}
          {isRejected && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          {isResolved && <ResponseCard elements={answers} />}
        </Form>
      </Formik>
    </>
  );
};
